import React, { useEffect } from 'react';
import Base from './Base';

const FileRecover = () => {
    useEffect(() => {
        // Any initialization logic goes here
    }, []);

    return (
        <div>
            <h1>FileRecover page</h1>
        </div>
    );
};

export default FileRecover;